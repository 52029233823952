/**
 * SubscriptionUpgrade
 *
 * @author: exode <hello@exode.ru>
 */

import moment from 'moment';

import React from 'react';

import { useI18n } from '@/hooks/core';
import { SchoolItem } from '@/types/school';

import { Icon24StarCircleFillGreen } from '@vkontakte/icons';

import { gradient } from '@/styles/modules/gradient';
import { ProgressButton } from '@/components/Atoms/ProgressButton';

import { Container } from './SubscriptionUpgrade.styled';


interface Props {
    access?: SchoolItem['access'];
}


const SubscriptionUpgrade = (props: Props) => {

    const { access } = props;

    const { t } = useI18n('components.School.SubscriptionUpgrade');

    const demoProgress = 100 - (moment().diff(access?.updatedAt, 'days') / (access?.price?.accessDays || 30)) * 100;

    return (
        <Container className="w-[197px]">
            <ProgressButton stretched
                            size="l"
                            type="static"
                            mode="tertiary"
                            data-test="school.upgrade"
                            progress={demoProgress}
                            before={<Icon24StarCircleFillGreen className="z-[1]" width={20} height={20}/>}
                            className="text-primary thin-border bg-content"
                            progressClassName={[
                                '!opacity-20 !bg-gradient-to-br',
                                gradient.green500ToLime600,
                            ].join(' ')}>
                <span className="fs-15">
                    {access?.isActive ? t('upgrade') : t('activate')}
                </span>
            </ProgressButton>
        </Container>
    );
};


export { SubscriptionUpgrade };
