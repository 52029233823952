/**
 * UsePaymentCart
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { useState } from 'react';

import { APP_URL, IS_NATIVE } from '@/root/src/env';

import { ShopStore } from '@/store/user/shop';
import { PreferenceStore } from '@/store/preference/preference';
import { Router as RouterService } from '@/services/Utils/Router';

import { Notify, useRedirect } from '@/cutils';
import { useCopy, useI18n } from '@/hooks/core';
import { ProductCurrency } from '@/shared/types';

import {
    CartProduct,
    PaymentStatus,
    useCartUpsertMutation,
    useDiscountCheckCodeLazyQuery,
    useInvoiceCreateByCartMutation,
    useInvoiceCreateBySharedCartMutation,
    useInvoiceRecreatePaymentMutation,
} from '@/codegen/graphql';


interface Props {
    cartUuid?: string;
    currentPromoCode?: string;
}


export const usePaymentCart = (props: Props = {}) => {

    const { cartUuid, currentPromoCode } = props;

    const { t } = useI18n('hooks.apollo.payment');

    const { copy } = useCopy();
    const { redirect } = useRedirect();

    const [ hasPromo, setHasPromo ] = useState(!!currentPromoCode);
    const [ promoCode, setPromoCode ] = useState(currentPromoCode || '');
    const [ promoCodeError, setPromoCodeError ] = useState('');

    const [ sharedCartToCopyUuid, setSharedCartToCopyUuid ] = useState('');

    const [ checkDiscount, { loading: checkDiscountLoading } ] = useDiscountCheckCodeLazyQuery({
        notifyOnNetworkStatusChange: true,
        onError: error => setPromoCodeError(error.message),
        onCompleted: ({ discountCheckCode }) => {
            if (discountCheckCode) {
                ShopStore.merge({
                    promoCodes: _.uniq([
                        ...ShopStore.promoCodes,
                        discountCheckCode.code,
                    ]),
                });
            }
        },
    });

    const [ _upsertCartShared, { loading: upsertCartSharedLoading } ] = useCartUpsertMutation({
        onError: (error) => console.error(error),
        onCompleted: async (data) => {
            const link = `${APP_URL}/cart/${data.cartUpsert.uuid}`;

            setSharedCartToCopyUuid(link);

            await copy(link);
        },
    });

    const [ _createInvoice, { loading: createInvoiceLoading } ] = useInvoiceCreateByCartMutation({
        onError: error => console.error(error),
        onCompleted: ({ invoiceCreateByCart }) => {
            checkoutStatusAction({
                invoiceUuid: invoiceCreateByCart.uuid,
                checkoutUrl: invoiceCreateByCart.actualPayment?.checkoutUrl,
                actualPaymentStatus: invoiceCreateByCart.actualPayment?.status,
            });
        },
    });

    const [ _createInvoiceShared, { loading: createInvoiceSharedLoading } ] = useInvoiceCreateBySharedCartMutation({
        onError: error => console.error(error),
        onCompleted: ({ invoiceCreateBySharedCart: invoiceCreateByCart }) => {
            checkoutStatusAction({
                invoiceUuid: invoiceCreateByCart.uuid,
                checkoutUrl: invoiceCreateByCart.actualPayment?.checkoutUrl,
                actualPaymentStatus: invoiceCreateByCart.actualPayment?.status,
            });
        },
    });

    const [ recreateInvoicePayment, {
        loading: recreateInvoicePaymentLoading,
    } ] = useInvoiceRecreatePaymentMutation({
        onError: error => console.error(error),
        onCompleted: ({ invoiceRecreatePayment }) => {
            checkoutStatusAction({
                invoiceUuid: invoiceRecreatePayment.uuid,
                checkoutUrl: invoiceRecreatePayment.actualPayment?.checkoutUrl,
                actualPaymentStatus: invoiceRecreatePayment.actualPayment?.status,
            });
        },
    });

    const upsertCartShared = async () => (
        await _upsertCartShared({
            variables: {
                cart: {
                    isShared: true,
                    products: ShopStore.cart,
                    promoCodes: [ ...ShopStore.promoCodes ],
                },
            },
        })
    );

    const createInvoice = async (
        acquiringId: number,
        currency: ProductCurrency,
        products: CartProduct[],
    ) => {
        await _createInvoice({
            variables: {
                cart: {
                    currency,
                    products,
                    acquiringId,
                    promoCodes: [ ...ShopStore.promoCodes ],
                },
            },
        });
    };

    const createInvoiceShared = async (
        acquiringId: number,
        currency: ProductCurrency,
    ) => {
        await _createInvoiceShared({
            variables: {
                sharedCart: {
                    currency,
                    acquiringId,
                    uuid: cartUuid ?? '',
                    promoCodes: [ ...ShopStore.promoCodes ],
                },
            },
        });
    };

    const handleApplyPromoClick = () => {
        hasPromo
            ? promoCode && checkDiscount({ variables: { code: promoCode } })
            : setHasPromo(true);
    };

    const resetPromoCode = () => {
        ShopStore.merge({
            promoCodes: ShopStore.promoCodes.filter((v) => v !== promoCode),
        });

        setPromoCode('');
        setPromoCodeError('');
    };

    const checkoutStatusAction = (props: {
        actualPaymentStatus: PaymentStatus | undefined;
        invoiceUuid: string;
        checkoutUrl?: string | null;
    }) => {
        const {
            checkoutUrl,
            invoiceUuid,
            actualPaymentStatus,
        } = props;

        switch (actualPaymentStatus) {
            case PaymentStatus.WaitingPay:
                if (checkoutUrl) {
                    redirect(checkoutUrl, false, true);
                }

                !IS_NATIVE && PreferenceStore.merge({ spinner: true });

                ShopStore.merge({ promoCodes: [] }, !cartUuid);

                return;

            case PaymentStatus.Completed:
                return RouterService.pushPage(
                    '/payment/:invoiceUuid([0-9_A-Za-z_-]+)',
                    { invoiceUuid },
                );

            case PaymentStatus.Created:
            case PaymentStatus.Canceled:
                return Notify.vkui({
                    message: t('somethingWentWrongRepeat'),
                    appearance: 'error',
                });
        }
    };

    return {
        hasPromo,
        promoCode,
        promoCodeError,
        checkDiscountLoading,
        createInvoice,
        createInvoiceLoading,
        upsertCartShared,
        upsertCartSharedLoading,
        sharedCartToCopyUuid,
        createInvoiceShared,
        createInvoiceSharedLoading,
        recreateInvoicePayment,
        recreateInvoicePaymentLoading,
        setPromoCode,
        setPromoCodeError,
        resetPromoCode,
        handleApplyPromoClick,
    };
};
