/**
 * UpdateSchoolInput
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import * as yup from 'yup';

import { IsNotEmpty, IsOptional, IsString, Matches, MaxLength, MinLength } from 'class-validator';

import { regex } from '@/utils';

import { SchoolContactRole } from '@/codegen/graphql';
import { isMinMax, isNotEmpty } from '@/libs/class-validator/constants';


export class CreateSchoolInput {

    @IsString()
    @IsNotEmpty({ message: isNotEmpty('Название', 'пустым') })
    @MinLength(3, { message: isMinMax(3, 'Название', 'min', 'должно') })
    readonly name: string;

    @IsString()
    @IsNotEmpty({ message: isNotEmpty('Домен', 'пустым') })
    @MinLength(3, { message: isMinMax(3, 'Домен', 'min', 'должен') })
    readonly baseDomain: string;

}

export class UpdateSchoolInput {

    @IsOptional()
    @MinLength(3, { message: isMinMax(3, 'Имя', 'min') })
    readonly name: string;

    @IsOptional()
    @IsNotEmpty({ message: isNotEmpty('Базовый домен') })
    @Matches(regex.urlPath, { message: 'Неверный домен' })
    @MaxLength(20, { message: isMinMax(20, 'Домен', 'max', 'должно') })
    readonly baseDomain: string;

    @IsOptional()
    @Matches(regex.urlDomain, { message: 'Неверный домен' })
    readonly customDomain: string;

}

export class UpdateMenuItemInput {

    @IsNotEmpty({ message: isNotEmpty('Название', 'пустым') })
    readonly name: string;

    @IsNotEmpty({ message: isNotEmpty('Ссылка', 'пустой') })
    readonly link: string;

}

export class CreateDocumentItemInput {

    @IsNotEmpty({ message: isNotEmpty('Название', 'пустым') })
    readonly name: string;

    @IsNotEmpty({ message: isNotEmpty('Ссылка', 'пустой') })
    readonly location: string;

}

export class SchoolOrganizationUpdateInput {

    @IsNotEmpty({ message: isNotEmpty('Инн', 'пустым') })
    readonly inn: string;

    @IsNotEmpty({ message: isNotEmpty('Название', 'пустым') })
    readonly organizationName: string;

}

export const SchoolContactItemInputSchema = yup.object().shape({
    contacts: yup.array().of(
        yup.object().shape({
            name: yup.string(),
            phone: yup.string(),
            email: yup.string(),
            link: yup.string(),
            role: yup.string().oneOf(_.values(SchoolContactRole)).optional(),
        }),
    ),
});

export const SchoolAddressItemInputSchema = yup.object().shape({
    addresses: yup.array().of(
        yup.object().shape({
            name: yup.string(),
            address: yup.string(),
            locationLink: yup.string(),
        }),
    ),
});

export const SchoolFooterItemInputSchema = yup.object().shape({
    footer: yup.array().of(
        yup.object().shape({
            title: yup.string(),
            items: yup.array().of(
                yup.object().shape({
                    text: yup.string(),
                    link: yup.string(),
                }),
            ),
        }),
    ),
});

export const SchoolLogoIconInputSchema = yup.object().shape({
    blockSettings: yup.object().shape({
        logo: yup.object().shape({
            text: yup.string().nullable(),
        }),
    }),
});
