/**
 * PhoneInput
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useState } from 'react';

import { PhoneInput as Phone, PhoneInputProps } from 'react-international-phone';

import { Container } from './PhoneInput.styled';


interface Props extends PhoneInputProps {

}


const PhoneInput = (props: Props) => {

    const { onFocus, onBlur } = props;

    const [ hasFocus, setHasFocus ] = useState(false);

    return (
        <Container className="thin-border vk-rounded" hasFocus={hasFocus}>
            <Phone {...props} defaultCountry="uz" onFocus={(e) => {
                onFocus?.(e);
                setHasFocus(true);
            }} onBlur={(e) => {
                onBlur?.(e);
                setHasFocus(false);
            }}/>
        </Container>
    );
};


export { PhoneInput };
