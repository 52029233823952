/**
 * SubscriptionUpgrade styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Container = styled.div`
    .vkuiButton__before {
        z-index: 1;
    }
`;
